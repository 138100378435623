import * as Dropzone from 'javascripts/dropzone/dropzone'
import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class OccurrenceFileUploadBase {
  constructor(dropzone_form, image_preview_container, document_preview_container, clickable, preview_template, param_name, validate_files = false) {
    this.dropzone_form = $(dropzone_form)
    this.previews_container = image_preview_container
    this.image_preview_container = image_preview_container
    this.document_preview_container = document_preview_container
    this.clickable = clickable
    this.preview_template = preview_template
    this.param_name = param_name
    this.validate_files = validate_files
  }

  call() {
    if (!$(this.dropzone_form).get(0)) return
    if (!$(this.document_preview_container).get(0)) return
    if (!$(this.image_preview_container).get(0)) return
    if (!$(this.previews_container).get(0)) return
    if (!$(this.clickable).get(0)) return
    if (!$(this.preview_template).get(0)) return

    const that = this

    $('.js-dropzone-upload-link').addClass('hide')
    $('.js-dropzone-success-button').removeClass('hide')
    $('.dz-default.dz-message').addClass('hide')
    that.dropzone_form.removeClass('dropzone')

    const preview_node = $(that.preview_template)
    const preview_template_html = preview_node.get(0).outerHTML

    that.dropzone_form.dropzone({
      createImageThumbnails: false,
      clickable: that.clickable,
      paramName: that.param_name,
      method: 'put',
      saveUploads: '#dropzone-save-uploads',
      previewsContainer: that.previews_container,
      previewTemplate: preview_template_html,
      dictFileTooBig: I18n.t(translations(), 'frontend.pages.occurrences.file_too_big'),
      acceptedFiles: 'image/jpeg, image/pjpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      dictInvalidFileType: I18n.t(translations(), 'frontend.pages.occurrences.not_permitted_file'),
      autoProcessQueue: false,
      dictMaxFilesExceeded: I18n.t(translations(), 'frontend.pages.occurrences.max_files_exceeded'),
      maxFiles: 100,
      maxFilesize: 10,
      parallelUploads: 0,

      queuecomplete: () => {
        $(".js-occurrence-dropzone-upload-link").attr('disabled', false)
      },

      accept: (file, done) => {
        // check if the file has special characters line + and - and ( ) and other special characters in the name if so alert the user

        if (file.name.match(/\+/)) {
            done(I18n.t(translations(), 'frontend.pages.occurrences.special_characters'));
            return
        }

        done()
      },

      addedfile: function (file) {
        this.options.parallelUploads = this.options.parallelUploads + 1

        file.previewElement = Dropzone.createElement(this.options.previewTemplate.trim());

        let imagePreviewContainer = $(that.image_preview_container).get(0);
        let documentPreviewContainer = $(that.document_preview_container).get(0);

        if (file.type == 'application/pdf' || file.type.includes('spreadsheet')) {
          $(documentPreviewContainer).prepend($(file.previewElement))

          if (file.type.includes('spreadsheet')) {
            $(file.previewElement).find("[data-dz-thumbnail]").remove()
            $(file.previewElement).find("[data-dz-name]").html(`<i class="fas fa-file-excel"></i> ${file.name}`)
          }
        } else {
          $(imagePreviewContainer).prepend($(file.previewElement))
        }

        $(file.previewElement)
          .find("[data-dz-name]")
          .html(file.name)
          .end()
          .find("[data-dz-size]")
          .html(this.filesize(file.size))
          .parent().find('.file-percent').text("0%")

        that.print_images_count_on_form()
        that.toggle_submit_button()

        const file_preview = $(file.previewElement)
        const remove_element = file_preview.find('[data-js-dz-remove]').removeClass('hide-important')

        remove_element.on('click', () => {
          this.removeFile(file)
          that.toggle_submit_button()
          that.print_images_count_on_form()
        })

        const retry_element = file_preview.find('[data-js-dz-retry]')
        retry_element.on('click', () => {
          this.processFile(file)
        })
      },

      sending: (file) => {
        $(".js-occurrence-dropzone-upload-link").attr('disabled', true)
        $(file.previewElement).find('[data-js-dz-retry]').addClass('hide-important')
      },

      uploadprogress: (file, progress, bytesSent) => {
        if (file.previewElement) {
          const node = $(file.previewElement).find("[data-dz-uploadprogress]")

          node.parent().find('.file-percent').text(`${progress.toFixed(0)}%`)
          node.css('width', `${progress}%`)
        }
      },

      success: (file) => {
        $(file.previewElement)
          .find('[data-js-dz-remove], [data-js-dz-retry]').remove().end()
          .find('.progress')
          .removeClass('progress-bar-primary progress-bar-danger')
          .addClass('progress-bar-success')
      },

      error: function (file, response, xhr) {
        $(file.previewElement).find('.progress')
          .removeClass('progress-bar-primary')
          .addClass('progress-bar-danger')
          .end()
          .find('.file-percent').text('0%')

        if (xhr) {
          let extra_message = that.process_message_error(response)

          Error.show_alert_flash_msg(
            `${I18n.t(translations(), 'frontend.pages.occurrences.happened_a_error')} \"${file.name}\"${extra_message}`
          )

          $(file.previewElement).find('[data-js-dz-retry]').removeClass('hide-important').end()
            .find('[data-js-dz-remove]').addClass('hide-important')

          return
        }

        Error.show_alert_flash_msg(response)
        this.removeFile(file)
        that.print_images_count_on_form()
      },
    })
  }

  process_message_error(response) {
    let extra_message = ''
    if (response.message) {
      extra_message = response.message.length > 65 ?
        `${response.message.substring(0, 65)}...` :
        `${response.message}.`
      return `: ${extra_message}`
    }

    return `: ${response.substring(0, 50)}...`
  }

  print_images_count_on_form() {
    const dropzone_instance = this.dropzone_form.prop('dropzone')
    const images_count = dropzone_instance.files.length;

    const dropzone_instance_class = dropzone_instance.clickableElements[0].id ? `.${dropzone_instance.clickableElements[0].id}` : ''
    const count_class = `.js-occurrence-images-count${dropzone_instance_class}`

    if (images_count == 0) {
      return $(count_class).html(I18n.t(translations(), 'frontend.pages.occurrences.any_image_selected'))
    }

    let message = ''

    if (images_count > 1) {
      message = `${images_count} ${I18n.t(translations(), 'frontend.pages.occurrences.many_selected_images')}`
    } else {
      message = `${images_count} ${I18n.t(translations(), 'frontend.pages.occurrences.one_selected_image')}`
    }

    $(count_class).html(message)
  }

  toggle_submit_button() {
    const { dropzone_form } = this

    if (!this.validate_files) {
      return
    }

    const submit_button = dropzone_form.find(':submit')

    if (dropzone_form.prop('dropzone').files.length) {
      submit_button.prop('disabled', false)
      return
    }

    submit_button.prop('disabled', true)
  }
}
